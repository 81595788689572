import _react from "react";
var exports = {};

var __importStar = exports && exports.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

const React = __importStar(_react);

function useTimeout(callback, delay) {
  const savedCallback = React.useRef(); // Remember the latest callback.

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]); // Set up the interval.

  React.useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    if (delay !== null) {
      let id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
}

exports.useTimeout = useTimeout;
export default exports;