import _react from "react";
var exports = {};

var __importStar = exports && exports.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

const React = __importStar(_react);

const Alert = ({
  id,
  title,
  onClose
}) => {
  return React.createElement("div", {
    id: id,
    className: "Toaster__alert"
  }, typeof title === "string" ? React.createElement("div", {
    className: "Toaster__alert_text"
  }, title) : title, onClose && React.createElement(Close, {
    onClose: onClose
  }));
};

const Close = ({
  onClose
}) => React.createElement("button", {
  className: "Toaster__alert_close",
  type: "button",
  "aria-label": "Close",
  onClick: onClose
}, React.createElement("span", {
  "aria-hidden": "true"
}, "\xD7"));

exports.default = Alert;
export default exports;