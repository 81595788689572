var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const POSITIONS = {
  top: "top",
  "top-left": "top-left",
  "top-right": "top-right",
  bottom: "bottom",
  "bottom-left": "bottom-left",
  "bottom-right": "bottom-right"
};
exports.default = POSITIONS;
export default exports;